<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>学院设置</el-breadcrumb-item>
      <el-breadcrumb-item>帮助管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="productName">
            <div style="display: inline-block" class="divSpan">产品名称：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.productName"
                :options="ProductStatus"
                :optionKey="ProductStatuskeys"
                Splaceholder="请选择搜索的产品"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
          <el-button type="primary" class="buttons" @click="addProduct"
            >新增产品</el-button
          >
        </el-form>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="ProductList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="name"
            label="产品名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="subject"
            label="产品logo"
            min-width="150px"
          ></el-table-column>

          <el-table-column
            prop="userId"
            label="建设状态"
            min-width="100px"
          ></el-table-column>

          <el-table-column
            prop="userId"
            label="学习人次"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="payTime"
            label="更新时间"
            min-width="150px"
          ></el-table-column>

          <el-table-column label="操作" min-width="150px" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="primary">上移</el-button>
              <el-button size="mini" type="primary">下移</el-button>
              <el-button size="mini" type="primary">编辑</el-button>
              <el-button size="mini" type="primary">启用</el-button>
              <el-button
                size="mini"
                type="danger"
                @click="enableVideo(scope.row)"
                >禁用</el-button
              >
              <el-button size="mini" type="primary">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 新增产品 -->
    <el-dialog
      :close-on-click-modal="false"
      title="新增产品"
      :visible.sync="addProductDialog"
      width="30%"
      @close="createDialogClosed"
    >
      <el-form
        :model="createForm"
        :rules="createFormRules"
        ref="createFormRef"
        label-width="120px"
      >
        <el-form-item label="所属产品：" prop="product">
          <ELselect
            v-model="createForm.product"
            :options="ProductStatus"
            :optionKey="ProductStatuskeys"
            Splaceholder="请选择"
            @clear="getList"
            @change="changeType"
          ></ELselect>
        </el-form-item>

        <el-form-item label="产品LOGO：" prop="Icons"> </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addProductDialog = false">取消</el-button>
        <el-button type="primary" @click="confirmAdd">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ELselect from '@/components/myself/el_select'

export default {
  components: { ELselect },
  data () {
    return {
      queryinfo: {
        productName: '',
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      ProductList: [],
      ProductStatus: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 0,
          label: '低代码平台'
        },

        {
          value: 2,
          label: '大数据平台'
        }],
      ProductStatuskeys: {
        value: 'value',
        label: 'label',
        label2: ''
      },

      addProductDialog: false,
      createForm: {
        product: '',
        Icons: '',
        Iconsize: '',
      },

      createFormRules: {
        product: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        Icons: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
      },
      KindID: ''
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () { },

    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getList()
    },
    dateSerach () {
      this.getList()
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },

    addProduct () {
      this.addProductDialog = true
    },
    createDialogClosed () {
      this.$refs.createFormRef.resetFields()

    },
    changeType (para) {
      this.KindID = para
    },

    confirmAdd () { }

  }
}
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
}

/deep/ .el-form-item__content {
  margin-right: 30px;
}

.buttons {
  float: right;
  margin-right: 100px;
}
</style>
